import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { createTheme, ThemeProvider, useMediaQuery } from '@mui/material'

import { DARK_PALETTE } from '@app/components/ui/theme/palette/dark'
import { LIGHT_PALETTE } from '@app/components/ui/theme/palette/light'
import { THEME } from '@app/components/ui'

import { LocalStorageService } from '@data/common/services'
import { LocalStorageValues } from '@data/common/enums'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import Font from '@app/asset/fonts/Inter-Regular.ttf'

import type { Dispatch, FC, PropsWithChildren, SetStateAction } from 'react'

interface IThemeModeContext {
  mode: 'light' | 'dark'
  setMode: Dispatch<SetStateAction<'light' | 'dark'>>
  orientationType: IOrientationType
}

interface IOrientationType {
  angle: number | undefined
  type: OrientationType | undefined
}

interface IThemModeLocalStorage {
  mode: 'light' | 'dark'
}

/* eslint-disable @typescript-eslint/no-unnecessary-condition, @typescript-eslint/no-magic-numbers */

const ThemModeContext = createContext<IThemeModeContext>({
  mode: 'light',
  orientationType: {
    angle: window.screen?.orientation === undefined ? undefined : window.screen.orientation.angle,
    type: window.screen?.orientation === undefined ? undefined : window.screen.orientation.type,
  },
  setMode: () => {
    //
  }
})

const useThemeModeContext = (): IThemeModeContext => useContext(ThemModeContext)

const _getThemeStorage = (isDark: boolean): IThemModeLocalStorage => {
  try {
    return LocalStorageService.get<IThemModeLocalStorage>(LocalStorageValues.THEME_MODE)
  } catch (e) {
    LocalStorageService.set(LocalStorageValues.THEME_MODE, { mode: isDark ? 'dark' : 'light' })
    return { mode: isDark ? 'dark' : 'light' }
  }
}

const ThemeMode: FC<PropsWithChildren> = ({ children }) => {
  const isDark = useMediaQuery('(prefers-color-scheme: dark)')
  const [mode, setMode] = useState<'light' | 'dark'>(_getThemeStorage(isDark).mode)
  const [orientationType, setOrientationType] = useState<IOrientationType>({
    angle: window.screen?.orientation === undefined ? undefined : window.screen.orientation.angle,
    type: window.screen?.orientation === undefined ? undefined : window.screen.orientation.type,
  })

  const context = useMemo(() => ({
    mode, setMode, orientationType
  }), [mode, orientationType])

  const themePalette = useMemo(() => createTheme({
    palette: mode === 'dark' ? DARK_PALETTE : LIGHT_PALETTE,
    ...THEME
  }), [mode])

  const theme = useMemo(() => createTheme(themePalette, {
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          '@font-face': {
            fontFamily: 'MyInter',
            // @typescript-eslint/restrict-template-expressions
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            src: `url("${Font}")`
          },
          body: {
            backgroundColor: themePalette.palette.bg.default,
            transition: themePalette.transitions.create(
              ['background-color'], { duration: themePalette.transitions.duration.standard }
            ),
            '.chart-error-component-container': {
              'background': themePalette.palette.bg.default
            },
            '.chart-modal-drag-container .chart-modal': {
              'background': themePalette.palette.bg.secondary,
              'color': themePalette.palette.text.primary
            },
            '.chart-toolbar-modal-drag, .chart-content-modal-close': {
              'color': themePalette.palette.text.primary
            },
            '.chart-content-modal-content .chart-content-modal-header': {
              'border-bottom': `1px solid ${themePalette.palette.bg.accent}`,
            },
            '.chart-content-modal-content .chart-content-modal-footer .chart-button-container .chart-button': {
              'background': themePalette.palette.bg.accent
            },
            '.chart-button-container .chart-button, .chart-button-tooltip-container .chart-button-tooltip': {
              'font-family': 'MyInter',
              'background': themePalette.palette.bg.secondary,
              'color': themePalette.palette.text.primary
            },
            [`
              .chart-select-container .chart-select,
              .chart-select-container .chart-select-button,
              .chart-dropdown-container .chart-select-dropdown,
              .chart-dropdown-trigger-container .chart-dropdown-trigger,
              .chart-dropdown-trigger-container .chart-dropdown-trigger-button,
              .chart-dropdown-dropdown-container .chart-dropdown
            `]: {
              'font-family': 'MyInter',
              'background': themePalette.palette.bg.secondary,
              'color': themePalette.palette.text.primary
            },
            '.chart-dropdown-container .chart-select-dropdown .chart-select-list .chart-select-list-item:hover': {
              'background': themePalette.palette.bg.accent
            },
            '.chart-button-container .chart-button:hover': {
              'background': themePalette.palette.bg.secondary
            },
            '.chart-button-container .chart-button polyline, .chart-button-container .chart-button line': {
              'stroke': themePalette.palette.text.primary
            },
            '.chart-input-tooltip-container .chart-input-tooltip': {
              'font-family': 'MyInter',
              'background': themePalette.palette.bg.secondary,
              'color': themePalette.palette.text.primary
            },
            [`
              .chart-select-container.type-secondary .chart-select,
              .chart-dropdown-container.type-secondary .chart-select-dropdown
            `]: {
              'background': themePalette.palette.bg.accent
            },
            '.chart-input-container.chart-input-container-text .chart-input-text': {
              'color': themePalette.palette.text.primary,
              'background': themePalette.palette.bg.accent
            },
            '.chart-input-container.chart-input-container-range .chart-input-range': {
              'background-image': `linear-gradient(90deg, ${themePalette.palette.bg.default}, #2962ff)`,
            },
            '.chart-input-container.chart-input-container-file .chart-input-label-file': {
              'border': `2px dashed ${themePalette.palette.bg.accent}`
            },
            '.chart-input-container.chart-input-container-checkbox.checkbox-button .chart-input-label-checkbox': {
              'font-family': 'MyInter',
              'background': themePalette.palette.bg.secondary,
              'color': themePalette.palette.text.primary
            },
            '.chart-input-container.chart-input-container-checkbox.checkbox-button .chart-input-label-checkbox:hover': {
              'background': themePalette.palette.bg.secondary
            },
            '.chart-text': {
              'color': themePalette.palette.text.primary
            },
            '.chart-preset-profit.side-buy .chart-text:first-child': {
              'color': themePalette.palette.chart.buy
            },
            '.chart-preset-profit.side-sell .chart-text:first-child': {
              'color': themePalette.palette.chart.sell
            },
            '.chart-preset-profit.color-buy .chart-text:last-child': {
              'color': themePalette.palette.chart.buy
            },
            '.chart-preset-profit.color-sell .chart-text:last-child': {
              'color': themePalette.palette.chart.sell
            },
            '.chart-preset-profit.color-default .chart-text:last-child': {
              'color': themePalette.palette.text.primary
            }
          }
        }
      },
      ...THEME.components
    }
  }), [themePalette])

  useEffect(() => {
    if (orientationType === undefined) {
      const find = document.querySelector('meta[name=\'viewport\']')
      if (find !== null) {
        find.setAttribute(
          'content',
          find.getAttribute('content')?.replace(', viewport-fit=cover', '') as unknown as string
        )
      }
    }
  }, [orientationType])

  useEffect(() => {
    const find = document.querySelector('meta[name=\'theme-color\']')
    find?.setAttribute('content', theme.palette.bg.default)
  }, [mode])

  useEffect(() => {
    window.addEventListener('orientationchange', (event) => {
      setTimeout(() => {
        setOrientationType({
          angle: event.target?.screen.orientation.angle,
          type: event.target?.screen.orientation.type
        })
      }, 500)
    })
  }, [])

  return (
    <ThemModeContext.Provider value={ context }>
      <ThemeProvider theme={ theme }>
        { children }
      </ThemeProvider>
    </ThemModeContext.Provider>
  )
}

export { useThemeModeContext }

export default ThemeMode
