import { useCallback, useEffect, useState } from 'react'

import { useAuthContext } from '@app/common/auth'
import { useInternetConnectionContext } from '@app/components/tools/components/internet-connection'

import { DetectNotificationsPresenter } from '@app/common/notifications/presenter'

import { useSettingsContext } from '@app/common/settings'
import { useTradeContext } from '@app/pages/trade/trade-context'

import { AudioService } from '@data/common/services'

import type { Dispatch, SetStateAction } from 'react'

import type { IShowMessagePayload } from '@app/common/notifications/presenter'

import type { IExecutedDetectDTO } from '@domain/stocks/interfaces'

const detectNotificationsPresenter = new DetectNotificationsPresenter()
const minute = 60000
let hasBeenUserAction = false

interface IDetectNotificationsResponse {
  executedDetectList: IExecutedDetectDTO[]
  setExecutedDetectList: Dispatch<SetStateAction<IExecutedDetectDTO[]>>
  countUnreadMessage: number
  setCountUnreadMessage: Dispatch<SetStateAction<number>>
  isOpenNotificationModal: boolean
  setIsOpenNotificationModal: Dispatch<SetStateAction<boolean>>
}

const useDetectNotifications = (): IDetectNotificationsResponse => {
  const { isAuth, user } = useAuthContext()
  const { PairEntity } = useTradeContext()
  const { detectList } = useSettingsContext()
  const { status } = useInternetConnectionContext()

  const [executedDetectList, setExecutedDetectList] = useState<IExecutedDetectDTO[]>([])
  const [countUnreadMessage, setCountUnreadMessage] = useState(0)
  const [isOpenNotificationModal, setIsOpenNotificationModal] = useState(false)

  const checkUserAction = (): void => {
    hasBeenUserAction = true
  }

  const onUpdateExecutedDetectList = useCallback((value: IExecutedDetectDTO[]): void => {
    setExecutedDetectList(() => [...value])
  }, [setExecutedDetectList])

  const onShowExecutedDetectMessage = useCallback((value: IShowMessagePayload): void => {
    if (!isOpenNotificationModal) {
      setCountUnreadMessage((prev) => {
        prev += 1

        return prev
      })
    }

    setTimeout(() => {
      setExecutedDetectList((prev) => {
        const data = prev.filter((item) => item !== value.detect)

        if (data.length !== prev.length && !isOpenNotificationModal) {
          setCountUnreadMessage((_prev) => {
            if (_prev !== 0) _prev -= 1

            return _prev
          })
        }

        return data
      })
    }, value.setting.alertTime * minute)

    if (process.env.REACT_APP_BASE_DOMAIN !== undefined && hasBeenUserAction && value.setting.isEnableSound) {
      const src = `${process.env.REACT_APP_BASE_DOMAIN}/static/audio/mp3/sound-${value.setting.sound}.mp3`
      const audioService = new AudioService({ src })

      void audioService.play()
    }
  }, [setCountUnreadMessage, setExecutedDetectList, isOpenNotificationModal])

  detectNotificationsPresenter.setEvents({
    onUpdateExecutedDetectList: onUpdateExecutedDetectList,
    onShowExecutedDetectMessage: onShowExecutedDetectMessage
  })

  useEffect(() => {
    window.addEventListener('blur', checkUserAction)

    return () => {
      window.removeEventListener('blur', checkUserAction)
    }
  }, [])

  useEffect(() => {
    detectNotificationsPresenter.setPairEntity(PairEntity)
  }, [PairEntity])

  useEffect(() => {
    detectNotificationsPresenter.setDetectSettingList(detectList)
  }, [detectList])

  useEffect(() => {
    detectNotificationsPresenter.setExecutedDetectList(executedDetectList)
  }, [executedDetectList])

  useEffect(() => {
    if (status === 'online' && isAuth && user) {
      detectNotificationsPresenter.subscribe(user)
    }

    return () => {
      detectNotificationsPresenter.unsubscribe()
    }
  }, [status, isAuth, user])

  return {
    executedDetectList,
    setExecutedDetectList,
    countUnreadMessage,
    setCountUnreadMessage,
    isOpenNotificationModal,
    setIsOpenNotificationModal
  }
}

export { useDetectNotifications }
