enum InternalCode {
  VALIDATION_ERROR = 1000,
  PROPERTY_ALREADY_EXIST = 1001,
  PROPERTY_NOT_FOUND = 1002,
  PROPERTY_IS_REQUIRED = 1003,
  PROPERTY_IS_INVALID = 1004,
  SERVER_ERROR = 1005,
  LIMIT_ERROR = 1006,
  INVALID_API_KEY = 1007,
  USER_ALREADY_2FA = 1008,
  INVALID_USER_CODE = 1009,
  USER_NEED_GA_CODE = 1010,
  PROPERTY_IS_UNSUPPORTED = 1011,
  API_KEY_NOT_FOUND = 1012,
  INVALID_STOP_PRICE = 1013,
  INVALID_USER_STATUS = 1014,
  USER_ALREADY_CONFIRMED = 1015,
  MULTIORDER = 1016,
  USER_CODE_ALREADY_SENDED = 1017,
  DETECT_IS_ACTIVE = 1018
}

enum ValidationCode {
  INVALID_EMAIL = 2000,

  INVALID_LENGTH = 2001,
  INVALID_LENGTH_MIN = 2002,
  INVALID_LENGTH_MAX = 2003,
  INVALID_LENGTH_CONTAIN = 2020,

  INVALID_NUMBERS = 2004,
  INVALID_SYMBOL = 2005,
  INVALID_LETTER_UPPER = 2006,
  INVALID_LETTER_LOWER = 20019,
  INVALID_CYRILLIC = 2018,
  INVALID_CODE = 2007,
  INVALID_VALIDATION_CODE = 2017,
  INVALID_BALANCE = 2008,
  INVALID_LIMIT = 2009,
  INVALID_REGEX = 2010,
  INVALID_STRICT_REGEX = 2019,
  INVALID_EXCEPTED_VALUE = 2011,
  INVALID_RANGE = 2012,
  INVALID_RANGE_MIN = 2017,
  PROPERTY_NOT_MATCH = 2013,
  PROPERTY_IS_NOT_UNIQUE = 2014,

  NEED_SYMBOL = 2015,
  CODE_REQUIRED = 2016
}

enum FilterCode {
  PERCENT_PRICE_BY_SIDE = 3001,
  LOT_SIZE = 3002,
  MIN_NOTIONAL = 3003,
  DEAL_MIN_NOTIONAL = 3015,
  ICEBERG_PARTS = 3004,
  MARKET_LOT_SIZE = 3005,
  MAX_POSITION = 3006,
  MAX_NUM_ORDERS = 3007,
  MAX_NUM_ICEBERG_ORDERS = 3008,
  TRAILING_DELTA = 3009,
  EXCHANGE_MAX_NUM_ORDERS = 3010,
  EXCHANGE_MAX_NUM_ALGO_ORDERS = 3011,
  PERCENT_PRICE = 3012,
  PRICE_FILTER = 3013,
  MAX_NUM_ALGO_ORDERS = 3014,
}

enum KeyboardCode {
  INVALID_INIT_COMBINATION = 4001
}

enum LicenseCodes {
  MAX_COUNT_ORDERS = 5001,
  MAX_ORDER_VOLUME = 5002,
  ENABLE_MULTIORDERS = 5003,
  MAX_AMOUNT = 5004
}

export {
  InternalCode,
  ValidationCode,
  FilterCode,
  KeyboardCode,
  LicenseCodes
}
