import { isNull } from 'lodash'

import { HttpServiceV1 } from '@data/common/services'

import { DetectType } from '@domain/setting-profile/detect'

import { InternalCode } from '@domain/common/enums'
import { ExceptionService } from '@domain/common/services'

import type { IHttpError } from '@data/common/interfaces'

import type {
  IBaseDetectItemResponse,
  IDetectsResponse,
  IDetectItemResponse,
  IDetectDeltaResponse,
  IDeltaResponse,
  IHttpDetectSettingError
} from '../interface'

import type {
  IDetectSettingDTO,
  IBaseDetectSettingDTO,
  IDetectDeltaSettingDTO,
  IDetectSettingRepository,
  IChangeActionDetectPayload,
  ICreateDetectPayload,
  IDeleteDetectPayload,
  IUpdateDetectPayload,
  IUpdateDetectDeltaPayload,
  IDeleteDetectDeltaPayload,
  ICreateDeltaPayload,
  IDetectLogicalErrors
} from '@domain/setting-profile/detect'

class DetectSettingRepository implements IDetectSettingRepository {

  public async getDetectList (): Promise<IDetectSettingDTO[]> {
    return HttpServiceV1.get<IDetectsResponse>('/strategy')
      .then((response) => {
        const activeList = response.active?.map((item) => this._transformDetect(item, true)) ?? []
        const inactiveList = response.inactive?.map((item) => this._transformDetect(item, false)) ?? []

        return [...this._sortArray(activeList), ...this._sortArray(inactiveList)]
      })
  }

  public async createDetect (payload: ICreateDetectPayload): Promise<IDetectSettingDTO> {
    return HttpServiceV1.post<IDetectItemResponse>('/strategy', { body: payload })
      .then((response) => {
        return this._transformDetect(response, false)
      })
      .catch((error: IHttpError<IHttpDetectSettingError>) => {
        throw this._throwErrors(error.errors)
      })
  }

  public async updateDetect (payload: IUpdateDetectPayload): Promise<IBaseDetectSettingDTO> {
    return HttpServiceV1.put<IBaseDetectItemResponse>(`/strategy/${payload.id}`, { body: payload })
      .then((response) => {
        return this._transformBaseDetectSetting(response, false)
      })
      .catch((error: IHttpError<IHttpDetectSettingError>) => {
        throw this._throwErrors(error.errors)
      })
  }

  public async deleteDetect (payload: IDeleteDetectPayload): Promise<boolean> {
    return HttpServiceV1.delete(`/strategy/${payload.id}`)
      .then(() => true)
      .catch((error: IHttpError<IHttpDetectSettingError>) => {
        throw this._throwErrors(error.errors)
      })
  }

  public async createDelta (payload: ICreateDeltaPayload): Promise<IDetectDeltaSettingDTO> {
    return HttpServiceV1.post<IDeltaResponse>('/strategy/delta', { body: payload })
      .catch((error: IHttpError<IHttpDetectSettingError>) => {
        throw this._throwErrors(error.errors)
      })
  }

  public async updateDetectDelta (payload: IUpdateDetectDeltaPayload): Promise<IDetectDeltaSettingDTO> {
    return HttpServiceV1.put<IDetectDeltaResponse>(`/strategy/delta/${payload.id}`, { body: payload })
      .catch((error: IHttpError<IHttpDetectSettingError>) => {
        throw this._throwErrors(error.errors)
      })
  }

  public async deleteDetectDelta (payload: IDeleteDetectDeltaPayload): Promise<boolean> {
    return HttpServiceV1.delete(`/strategy/delta/${payload.id}`)
      .then(() => true)
      .catch((error: IHttpError<IHttpDetectSettingError>) => {
        throw this._throwErrors(error.errors)
      })
  }

  public async startDetect (payload: IChangeActionDetectPayload): Promise<boolean> {
    return HttpServiceV1.put(`/strategy/${payload.id}/change-activity`, { body: { activate: true }})
      .then(() => true)
  }

  public async stopDetect (payload: IChangeActionDetectPayload): Promise<boolean> {
    return HttpServiceV1.put(`/strategy/${payload.id}/change-activity`, { body: { activate: false }})
      .then(() => true)
  }

  private _transformDetect (value: IDetectItemResponse, isActive: boolean): IDetectSettingDTO {
    const base = this._transformBaseDetectSetting(value, isActive)

    return { ...base, delta: value.delta === null ? [] : value.delta }
  }

  private _transformNullToString (value: string | null | number): string {
    return isNull(value) ? '' : value.toString()
  }

  private _sortArray (array: IDetectSettingDTO[]): IDetectSettingDTO[] {
    return array.sort((a, b) => a.id < b.id ? 1 : -1)
  }

  private _transformBaseDetectSetting (value: IBaseDetectItemResponse, isActive: boolean): IBaseDetectSettingDTO {
    return {
      id: value.id,
      name: value.name,
      type: value.type === 'PUMP' ? DetectType.PUMP : DetectType.DUMP,
      isActive: isActive,
      isEnableSound: value.soundOn,
      sound: value.soundType,
      alertTime: value.notificationDuration,
      pairList: value.byCoins ? null : value.pairListId,
      lastAssetList: value.byCoins ? value.coins ?? [] : [],
      minVolumePerHour: value.minHourlyVolume,
      maxVolumePerHour: this._transformNullToString(value.maxHourlyVolume),
      minVolumePerDay: value.minVolume,
      maxVolumePerDay: this._transformNullToString(value.maxVolume),
      priceInterval: parseInt(value.priceInterval),
      priceChange: value.priceRaise,
      minTradesPerSec: this._transformNullToString(value.perSecMin),
      maxTradesPerSec: this._transformNullToString(value.perSecMax),
      volumePerSec: this._transformNullToString(value.perSecVolume),
    }
  }

  private _throwErrors (error: IHttpDetectSettingError): ExceptionService<IDetectLogicalErrors> {
    let logical

    switch (error.message) {
      case 'strategy_is_active':
        logical = InternalCode.DETECT_IS_ACTIVE
        break
      default: logical = InternalCode.SERVER_ERROR
    }

    return ExceptionService.new({
      status: {
        code: logical,
        message: error.message
      },
      data: { logical }
    })
  }

}

export { DetectSettingRepository }
