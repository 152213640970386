import { useTranslation } from 'react-i18next'
import { deepmerge } from 'deepmerge-ts'

import { Checkbox, TextField } from '@mui/material'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

import { useResizePlatform } from '@app/pages/trade/components/trade-window/use-resize-platform'

import { messageErrorTranslate } from '@app/services/i18n'

import { INPUT_COMBOBOX as Component } from './_style'

import type { FieldError, Merge } from 'react-hook-form/dist/types'

import type { ChangeEvent, SyntheticEvent } from 'react'

import type { AutocompleteProps, FilterOptionsState } from '@mui/material'

interface IInputComboBoxOptions<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
  'renderOption'
  | 'renderInput'
  | 'onChange'
  | 'getOptionLabel'
  | 'filterOptions'
  | 'getOptionDisabled'
  | 'isOptionEqualToValue'
  > {
  isFullHeight?: boolean
  error?: Merge<FieldError, (FieldError | undefined)[]> | undefined
  isOptionEqualToValue?(option: unknown, value: unknown): boolean
  filterOptions? (options: unknown[], state: FilterOptionsState<unknown>): unknown[]
  getOptionDisabled? (options: unknown): boolean
  optionLabel (value: unknown): string
  onChange (value: unknown): void
  onTextFieldChange?(e: ChangeEvent): void
}

const InputComboBox = <
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>
(props: IInputComboBoxOptions<T, Multiple, DisableClearable, FreeSolo>): JSX.Element => {
  const { t } = useTranslation()
  const { isMobile } = useResizePlatform()

  const defaultProps = {
    noOptionsText: t('common/no-options'),
    size: 'small',
    multiple: true,
    isFullHeight: false,
    disableCloseOnSelect: true
  }

  props = deepmerge(defaultProps, props)

  const isError = props.error?.message !== undefined
  const errorObject = isError ? JSON.parse(String(props.error?.message)) as Record<string, string> : {}

  const handleChange = (_event: SyntheticEvent, value: unknown): void => {
    props.onChange(value)
  }

  const getInputPlaceholder = (): string => {
    if (props.value === undefined) return props.placeholder ?? ''
    if (props.multiple === true) return (props.value as T[]).length === 0 ? props.placeholder ?? '' : ''

    return ''
  }

  return (
    <>
      <Component.ComboBox
        value={ props.value }
        options={ props.options }
        disabled={ props.disabled }
        noOptionsText={ props.noOptionsText }
        getOptionLabel={ props.optionLabel }
        getOptionDisabled={ props.getOptionDisabled }
        isOptionEqualToValue={ props.isOptionEqualToValue }
        size={ props.size }
        multiple={ props.multiple }
        disableCloseOnSelect={ props.disableCloseOnSelect }
        sx={ props.sx }
        openText=""
        closeText=""
        clearText=""
        onChange={ handleChange }
        onBlur={ props.onBlur }
        filterOptions={ props.filterOptions }
        isMobile={ isMobile }
        isFullHeight={ props.isFullHeight! }
        isError={ isError }
        inputValue={ props.inputValue }
        slotProps={ props.slotProps }
        renderOption={ (_props, option, { selected }): JSX.Element => (
          <li { ..._props }>
            <Checkbox
              icon={ <CheckBoxOutlineBlankIcon fontSize="small" /> }
              checkedIcon={ <CheckBoxIcon fontSize="small" /> }
              checked={ selected }
            />

            { props.optionLabel(option) }
          </li>
        ) }
        renderInput={ (params): JSX.Element => (
          <TextField
            { ...params }
            onChange={ props.onTextFieldChange }
            placeholder={ getInputPlaceholder() }
          />
        ) }
      />

      { isError && (
        <Component.HelperText className={ isError ? 'Mui-error' : '' }>
          { isError ? t(errorObject.message, messageErrorTranslate(errorObject)) : '' }
        </Component.HelperText>
      ) }
    </>
  )
}

export { InputComboBox }
