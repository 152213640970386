const SLIDER_SIZE = {
  ten: 10,
  twentyFive: 25,
  seventyFive: 75,
  tickSizeMin: 3,
  tickSizeMax: 10,
  tickSizeScale: 1
}

const TICK_SIZE = [
  { id: 'left', label: 'trade-page/menu-graphic-form-left' },
  { id: 'right', label: 'trade-page/menu-graphic-form-right' },
]

const VOLUME_SIDE = [
  { id: 'left', label: 'trade-page/menu-graphic-form-left' },
  { id: 'right', label: 'trade-page/menu-graphic-form-right' },
]

const ORDER_LEVEL_MODE = [
  { id: 'absolute', label: 'settings-content/label-order-level-absolute' },
  { id: 'relative', label: 'settings-content/label-order-level-relative' },
]

const DEFAULT_PLOT_SCALE = [
  { id: 'tick', label: 'settings-content/label-tick' },
  { id: 'candle', label: 'settings-content/label-candle' },
]

const DROP_LIST_DEFAULT_SCALE = [
  { id: 5, label: '5.0%' },
  { id: 10, label: '10.0%' },
  { id: 20, label: '20.0%' },
  { id: 50, label: '50.0%' },
  { id: 100, label: '100.0%' },
  { id: -1, label: 'AUTO' }
]

export {
  SLIDER_SIZE,
  TICK_SIZE,
  ORDER_LEVEL_MODE,
  VOLUME_SIDE,
  DEFAULT_PLOT_SCALE,
  DROP_LIST_DEFAULT_SCALE
}
