import { WebsocketService } from '@data/common/services'

import { DetectType } from '@domain/setting-profile/detect'

import type { TimerId } from '@domain/common/utils'

import type { IExecutedDetectDTO } from '@domain/stocks/interfaces'

import type { IExecutedDetectSocketResponse } from './interfaces'

class ExecutedDetectSocket {

  private _socket?: WebsocketService<IExecutedDetectSocketResponse>

  private _timerId: TimerId | null = null

  public subscribe (id: string): void {
    this._socket = new WebsocketService('REACT_APP_BASE_WS_DOMAIN', `ws?channel=detect_${id}`)

    const tenSec = 10000

    this._socket.onConnect(() => {
      this._timerId = setInterval(() => {
        this._socket?.send('')
      }, tenSec)
    })
  }

  public unsubscribe (): void {
    this._socket?.close()

    if (this._timerId) {
      clearInterval(this._timerId)
      this._timerId = null
    }
  }

  public onMessage (callback: (value: IExecutedDetectDTO) => void): void {
    this._socket?.onMessage((message) => {
      callback({
        type: message.type === 'PUMP' ? DetectType.PUMP : DetectType.DUMP,
        name: message.name,
        symbol: message.symbol,
        percent: message.price_change_percent.toString(),
        volume: message.volume.toString(),
        impulse: message.impulse_end - message.impulse_start,
        timestamp: message.detected_time * 1000
      })
    })
  }

}

export { ExecutedDetectSocket }
