import { RequiredValidator, ValidationChain } from '@domain/common/utils/validators'

import { CreateDetectValidation } from '../create'

import type { IDetectValidation, IUpdateFieldValidation } from '../../interface'

class UpdateDetectValidation extends CreateDetectValidation implements IDetectValidation<IUpdateFieldValidation> {

  public override validate (): IUpdateFieldValidation {
    const result = super.validate()

    return {
      id: new ValidationChain([new RequiredValidator()]),
      ...result
    }
  }

}

export { UpdateDetectValidation }
